<template>
  <main-modal
      :active="true"
      @onClose="onClose"
      :bodyPadding="'0 60px'">
    <template v-slot:modalHeader>
      <a-button
          @click="onClose()"
          class="back-btn button-without-animation">
        <i class="anticon">
          <arrow/>
        </i>
        {{ $t('bases.bases_list') }}
      </a-button>
    </template>
    <template v-slot:modalBody>
      <div class="base-form-wrapp" style="height: auto;">
        <a-form :form="form" class="base-form create-form">
          <a-row>
            <a-col :lg="12">
              <a-form-item :label="$t('users.first_name')">
                <a-input
                    size="large"
                    :placeholder="$t('users.first_name_placeholder')"
                    v-decorator="['first_name', {rules: [
                      {required: false, message: $t('messages.requiredField')},
                      {min: 3, message: $t('messages.minLength') + 3},
                      {max: 255, message: $t('messages.maxLength') + 255}],
                    }]"
                >
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('users.last_name')">
                <a-input
                    size="large"
                    :placeholder="$t('users.last_name_placeholder')"
                    v-decorator="['last_name', {rules: [
                      {required: false, message: $t('messages.requiredField')},
                      {min: 3, message: $t('messages.minLength') + 3},
                      {max: 255, message: $t('messages.maxLength') + 255}],
                    }]"
                >
                </a-input>
              </a-form-item>
              <a-form-item label="Email">
                <a-input
                    size="large"
                    :placeholder="$t('organizations.enter_email')"
                    v-decorator="['email', {rules: [
                      {required: false, message: $t('messages.requiredField')},
                      {min: 3, message: $t('messages.minLength') + 3},
                      {max: 255, message: $t('messages.maxLength') + 255}],
                    }]"
                >
                </a-input>
              </a-form-item>
            </a-col>
            <a-col :lg="12">
              <a-form-item :label="$t('organizations.organizations_list')">
                <a-select
                    size="large"
                    :placeholder="$t('basics.select_organization')"
                    v-decorator="['organization_id',{rules: [
                      {required: false, message: $t('messages.requiredField')}],
                      initialValue: selected
                    }]"
                    @change="handleSelectChanges"
                >
                  <a-select-option  value="-1">
                    {{ $t('bases.no_organization') }}
                  </a-select-option>
                  <a-select-option v-for="item in organizationsList" :value="item.id" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item :label="$t('users.nfc_badge')">
                <a-input
                    size="large"
                    :placeholder="$t('users.nfc_placehodler')"
                    v-decorator="['nfc_badge_id', {rules: [
                      {required: false, message: $t('messages.requiredField')},
                      {min: 3, message: $t('messages.minLength') + 3},
                      {max: 255, message: $t('messages.maxLength') + 255}],
                    }]"
                >
                </a-input>
                <a-input
                    v-show="false"
                    size="large"
                    v-decorator="['test', {initialValue: getOrganizationDetails.phone}]"
                >
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('picos.user_id')">
                <a-input
                    size="large"
                    :placeholder="$t('users.user_id_placeholder')"
                    v-decorator="['user_id', {rules: [
                      {required: false, message: $t('messages.requiredField')},
                      {min: 3, message: $t('messages.minLength') + 3},
                      {max: 255, message: $t('messages.maxLength') + 255}],
                    }]"
                >
                </a-input>
              </a-form-item>
              <a-form-item :label="$t('picos.external_id')">
                <a-input
                    size="large"
                    :placeholder="$t('users.external_id_placeholder')"
                    v-decorator="['external_id', {rules: [
                      {required: false, message: $t('messages.requiredField')},
                      {min: 3, message: $t('messages.minLength') + 3},
                      {max: 255, message: $t('messages.maxLength') + 255}],
                    }]"
                >
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <div class="form-action-btn">
          <a-button @click="onClose">
            {{ $t('basics.cancel') }}
          </a-button>
          <a-button
              type="primary"
              :loading="loadingState"
              :disabled="loadingState"
              @click="handleSubmit"
          >
            {{ $t('users.create_user') }}
          </a-button>
        </div>
      </div>
    </template>
  </main-modal>
</template>

<script>
import MainModal from '@/components/MainModal/MainModal';
import arrow from '@/assets/icons/back.svg?inline';

import {requiredField, minLength, maxLength} from '@/utils/messages';

export default {
  name: 'NewUser',
  props: ['active'],
  components: {
    arrow,
    MainModal
  },
  data() {
    return {
      requiredField,
      minLength,
      maxLength,
      loadingState: false,
      selected: '-1',
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {
    let modal = document.getElementsByClassName('modal-body')[0];
    if (modal) {
      modal.addEventListener('scroll', this.handleScroll);
    }

    if (this.getOrganizationId) {
      this.$store.dispatch('detailOrganization/GetOrganization', this.getOrganizationId);
    }
  },
  computed: {
    role() {
      return parseInt(this.$store.getters['getRole']);
    },
    getOrganizationId() {
      return this.$store.getters['getOrganizationId'];
    },
    organizationsList() {
      return this.$store.getters['organizations/getOrganizationsWithoutLink']
    },
    organizations() {
      return this.$store.getters['organizations/getOrganizations'];
    },
    managerOrganization() {
      return this.$store.getters['detailOrganization/getOrganizationDetails'];
    },
    getOrganizationDetails() {
      return this.$store.getters['detailOrganization/getOrganizationDetails'];
    },
    isEmptyDetail() {
      return true;
    }
  },
  watch: {
    role: {
      immediate: true,
      handler(id) {
        if (id === 1) {
          this.$store.dispatch('organizations/GetOrganizationDropdownList');
        }
      }
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    handleScroll() {
      let openedSelects = document.getElementsByClassName('ant-select ant-select-open');
      let selectDropdowns = document.getElementsByClassName('ant-select-dropdown');

      openedSelects.forEach(element => {
        if (element) {
          element.classList.remove(...['ant-select-open', 'ant-select-focused']);
        }
      });

      selectDropdowns.forEach(element => {
        if (element) {
          element.style.display = 'none';
        }
      });
    },
    handleSelectChanges(value) {
      this.selected = value;
      if (this.selected > 0) {
          this.$store.dispatch('detailOrganization/GetOrganization', value);
      } else {
          this.$store.commit('detailOrganization/SET_ORGANIZATIONS_DETAIL', {});
      }
    },
    handleSubmit() {
      this.loadingState = true;
      this.form.validateFields((err, values) => {
        if (values.organization_id === 'no_organization') {
          values.organization_id = null;
        }

        if (!!err) {
          setTimeout(() => this.loadingState = false, 1000);
          return;
        }

        this.$store.dispatch('users/CreateUser', values)
            .then(() => {
              this.$emit('created');
            })
            .catch(error => {
              const errors = error.response.data?.error?.errors;
              if (errors) {
                for (const [key, value] of Object.entries(errors)) {
                  this.form.setFields({
                    [key]: {
                      value: '',
                      errors: [new Error(value)]
                    }
                  });
                }
              }
              this.loadingState = false;
            });
      });
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>


